import * as React from "react"
import { graphql, Link } from 'gatsby'
import styled from "styled-components"
import Layout from '../../components/Layout'


const Links = styled.ul`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  h3 {
    margin-top: 0;
  }
`

const hardcodedPosts = [
  {
    position: 6, // from end of array
    frontmatter: {
      date: '2023-09-15',
      title: 'On hurricanes & decisions',
    },
    slug: 'hurricanes-and-decisions',
  }
]

const IndexPage = ({ data }) => {
  let posts = [...data.allMdx.nodes]

  if (posts.length == data.allMdx.nodes.length) {
    posts = [...posts.reverse()]
    hardcodedPosts.forEach(hp => {
      const position = posts.length - hp.position
      posts.splice(position, 0, hp)
    })
  }

  return (
    <Layout pageTitle="Fabio de Sousa">
      <Links>
        {posts.map(node => (
          <li>
            {node.frontmatter.date}
            <h3>
              <Link to={`${node.slug}`} key={node.slug}>
                {node.frontmatter.title}
              </Link>
            </h3>
          </li>
        ))}
      </Links>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: ASC}) {
      nodes {
        slug
        frontmatter {
          date
          title
        }
      }
    }
  }
`

export default IndexPage
